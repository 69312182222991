<template>
  <div id="NftPool" class="nft_pool_pamphlet">
    <div class="top">
      <!-- Big Title 영역 ==================================== -->
      <div class="big_title">
        <div class="title-wrap">
          <div class="title">
            NFT ART POOL
          </div>
          <div class="description"
               v-html="$t('nftpool.nftPoolMain')"
          />
        </div>
        <div class="calendar_box">
          <vc-calendar
            ref="calendar"
            is-expanded
            title-position="left"
            :attributes="attrs"
            :masks="masks"
            locale="en"
            @update:from-page="onUpdateCalendarPage"
          />
          <div class="calendar_text">
            <div
              class="item"
              v-for="(item, i) in calendarArtpoolList"
              :key="i"
              :style="{backgroundColor: item.primaryColor}"
            >
              {{ item.poolStartDate | moment("MM. DD") }} - {{ item.poolEndDate | moment("MM. DD") }}
              <template v-if="activeLanguage !== undefined && activeLanguage === 'ko'">
                {{ item.title || item.titleEn }}
              </template>
              <template v-else>
                {{ item.titleEn || item.title }}
              </template>
            </div>
          </div>
        </div>
      </div>

      <!-- Nft Pool Schedule 영역 ==================================== -->
      <div class="nft_pool_schedule">
        <div class="img-container">
          <img
            class="img_box"
            @click="onClickArtPoolListItem(artpoolSimpleInfo)"
            v-if="artpoolSimpleInfo.bannerImagePcEnUrl && activeLanguage === 'en'"
            :src="artpoolSimpleInfo.bannerImagePcEnUrl"
          />
          <img
            class="img_box"
            @click="onClickArtPoolListItem(artpoolSimpleInfo)"
            v-else-if="artpoolSimpleInfo.bannerImagePcUrl && activeLanguage === 'ko'"
            :src="artpoolSimpleInfo.bannerImagePcUrl"
          />
          <img
            class="img_box"
            @click="onClickArtPoolListItem(artpoolSimpleInfo)"
            v-else
            :src="`/img/threespace/artpool/artpool_round${artpoolSimpleInfo.roundNo}_banner.png`"
          />
<!--          <img-->
<!--            class="img_box"-->
<!--            src="/img/threespace/artpool/artpool_round8_main_bg.png"-->
<!--            @click="onClickArtPoolListItem(artpoolSimpleInfo)"-->
<!--          />-->
        </div>
      </div>
    </div>


    <!-- Nft Pool Text Slide 영역 ==================================== -->
    <div class="nft_pool_text_slide">
        <div class="text_slide_wrap">
            <div class="text_box">
              <template v-for="item in 6">
                <span :key="'f' + item">In Exhibit</span>
                <span :key="'s' + item">In Exhibit</span>
                <span :key="'t' + item">
                  {{ artpoolSimpleInfo.poolStartDate | moment("M.DD") }}
                  &nbsp;-&nbsp;
                  {{ artpoolSimpleInfo.poolEndDate | moment("M.DD") }}
                </span>
              </template>
            </div>
        </div>
    </div>


    <div class="artpool-description">
      <!-- What is an Art Pool 영역 ==================================== -->
      <div class="what_is_art_pool item">
        <div class="text_box">
          <div class="title">WHAT IS AN ART POOL?</div>
          <div class="subtitle">아트풀이란?</div>
        </div>
        <div class="description" v-html="$t('nftpool.whatIsArtpoolContent')"></div>
      </div>

      <!-- How to join 영역 ==================================== -->
      <div class="how_to_join item">
        <div class="text_box">
          <div class="title">HOW DO I PARTICIPATE?</div>
          <div class="subtitle">아트풀 참여 방법</div>
        </div>
        <div class="description section2">
          <div class="description-item" v-for="item in 5" :key="item">
            <div class="description-item-title">{{ item }}</div>
            <div class="description-item-description" v-html="$t(`nftpool.howToJoin.step${item}Detail`)"/>
          </div>
        </div>
      </div>

      <!-- Artist Benefits 영역 ==================================== -->
      <div class="artist_benefit item">
        <div class="text_box">
          <div class="title">ARTIST BENEFITS</div>
          <div class="subtitle">아티스트 베네핏</div>
        </div>
        <div class="description section3">
          <div class="description-item">
            <div class="benefit-title">BENEFIT 1</div>
            <div class="benefit-description">{{ $t("nftpool.artistBenefit.benefit1") }}</div>
          </div>
          <div class="description-item">
            <div class="benefit-title">BENEFIT 2</div>
            <div class="benefit-description">{{ $t("nftpool.artistBenefit.benefit2") }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="contact-us">
      <div class="title">CONTACT US</div>
      <div class="btn-wrap">
        <b-button class="artist" @click="onClickArtistApplyPage">
          APPLY AS ARTIST
        </b-button>
        <b-button class="corporation" @click="onClickContactUsPage">
          EXHIBIT WITH US
        </b-button>
      </div>
    </div>

    <div class="nft-pool-history">
      <div class="title">PREVIOUS EXHIBITIONS</div>
      <div class="nft-pool-list">
        <template v-for="(item,i ) in artpoolList">
          <div
            :key="'artpool-list' + i"
            @click="onClickArtPoolListItem(item)"
            class="item"
            :class="{active: artpoolSimpleInfo.idx === item.idx}"
          >
            <div class="img_box">
              <img :src="item.thumbnailImageUrl" />
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import BXBubbles from "../components/backgroundEffect/Bubbles.vue"

export default {
  name: "NftPool",
  components: {BXBubbles},
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.artPool.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.artPool.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ],
    }
  },
  data: function () {
    return {
      isBusy: false,
      perPage: 12,
      currentPage: 1,
      sort: [ { id: "roundNo" } ],
      showOpen1: false,
      showOpen2: false,
      showOpen3: false,
      externalParams: {
        keywordPoolType: "NFT",
      },
      masks: {
        title: "M",
        weekdays: "WWW"
      },
      attrs: [
        {
          key: 'today',
          dot: true,
          dates: new Date(),
        },
      ],
      windowWidth: 1920,
    }
  },
  computed: {
    ...mapState("gallery", ["artworkFileInfo"]),
    ...mapState("artpool", [
      "artpoolItems",
      "artpoolVersionOptions",
      "calendarArtpoolList",
      "artpoolSimpleInfo"
    ]),
    activeLanguage() {
      return this.getActiveLanguage();
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    artpoolList () {
      if (this.artpoolItems.content !== undefined && this.artpoolItems.content.length > 0) {
        return this.artpoolItems.content;
      } else {
        return [];
      }
    }
  },
  created() {
    // 달력 표시 정보
    let month = this.$moment().format("MM");
    let year = this.$moment().format("YYYY");
    this.getArtpoolsIncludedMonth({
      month: month,
      year: year
    }).then()
  },
  mounted() {
    this.windowWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
    const param = {
      codes: ["nft_pool_main_product"]
    };
    this.getCodeList(param).then(data => {
      // 작품
      let idxProduct = data[0].codeValue;
      this.getArtworkFile(idxProduct).then();
      // 아트풀 리스트
      this.requestArtpools();
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapActions("gallery", ["getArtworkFile"]),
    ...mapActions("code", ["getCodeList"]),
    ...mapActions("artpool", ["getArtpoolSimple", "getArtpools", "getArtpoolsIncludedMonth"]),
    getActiveLanguage() {
      return localStorage.getItem("language") || "en";
    },
    requestArtpoolSimple(idxArtpool) {
      this.getArtpoolSimple(idxArtpool).then();
    },
    handleResize(event) {
      this.windowWidth = window.innerWidth;
    },
    requestArtpools() {
      this.$store.commit("setShowLoading", {show: true, msg: ""});
      this.isBusy = true;
      this.getArtpools(
        this.getPagingParams(
          {
            page: this.currentPage - 1,
            pageSize: this.perPage,
            sorted: this.sort,
            filtered: [],
          },
          this.externalParams
        )
      )
      .then(() => {
        this.isBusy = false;
        let idxArtpool = 0;
        this.artpoolItems.content.forEach(el => {
          if (el.initActive) {
            idxArtpool = el.idx;
          }
          let calendarData = {
            highlight: {
              fillMode: 'solid',
              style: {
                backgroundColor: el.primaryColor
              },
              contentStyle: {
                color: "#000",
                fontWeight: "normal"
              }
            },
            dates: { start: new Date(el.poolStartDate), end: new Date(el.poolEndDate) },
          }
          this.attrs.push(calendarData);
        })
        if (idxArtpool !== 0) {
          this.requestArtpoolSimple(idxArtpool)
        }
        this.$store.commit("setShowLoading", { show: false, msg: "" });
      })
      .catch(error => {
        this.$log.error(error);
        this.isBusy = false;
        this.$store.commit("setShowLoading", { show: false, msg: "" });
      });
    },
    getPagingParams({ page, pageSize, sorted, filtered }, externalParams) {
      const params = {
        page,
        size: pageSize,
        ...externalParams
      };

      // sorting parameter
      let sorts = [];
      for (let i = 0, length = sorted.length; i < length; i++) {
        const sort = sorted[i];
        sorts.push(`${sort.id},${sort.desc ? "desc" : "asc"}`);
      }
      params["sort"] = sorts;

      // filtering parameter
      for (let i = 0, length = filtered.length; i < length; i++) {
        const filter = filtered[i];
        params[filter.id] = filter.value;
      }
      return params;
    },
    onUpdateCalendarPage(e) {
      let month = "0" + e.month;
      month = month.slice(-2);
      this.getArtpoolsIncludedMonth({
        month: month.toString(),
        year: e.year.toString()
      }).then()
    },
    onClickArtPoolListItem(artpool) {
      // let version = this.artpoolVersionOptions.find(el => el.roundNo === artpool.roundNo).version;
      // if (version === 1 ) {
      //   this.$router.push({ name: "NftPoolStake", params: { idxArtpool: artpool.idx }})
      // } else if (version === 2) {
      //   this.$router.push({ name: "NftPoolDetail", params: { idxArtpool: artpool.idx }})
      // }
      if(artpool.roundNo < 3 ) {
        this.$router.push({ name: "NftPoolStake", params: { idxArtpool: artpool.idx }})
      } else if(artpool.roundNo >= 3) {
        this.$router.push({ name: "NftPoolDetail", params: { idxArtpool: artpool.idx }})
      }
    },
    isProductFileTypeImage(_productFileType) {
      if (
        _productFileType !== undefined &&
        _productFileType.startsWith("image")
      ) {
        return true;
      } else {
        return false;
      }
    },
    replaceDisplayPosterUrl(item) {
      if (
        item.displayPoster !== undefined &&
        item.displayPoster !== null &&
        item.displayPoster !== "" &&
        item.displayPoster === "THREESPACE"
      ) {
        return item.displayPosterUrlThreespace; // 3space S3 URL
      } else {
        if (item.displayPosterUrl !== undefined && item.displayPosterUrl.startsWith("https://ipfs.io/")) {
          return item.displayPosterUrl.replace("https://ipfs.io/", "https://ipfs.3space.art/");
        }
        return item.displayPosterUrl;
      }
    },
    replaceDisplayDetailUrl(item) {
      if (
        item.displayDetailFile !== undefined &&
        item.displayDetailFile !== null &&
        item.displayDetailFile !== "" &&
        item.displayDetailFile === "THREESPACE"
      ) {
        return item.displayDetailUrlThreespace; // 3space S3 URL
      } else {
        if (
          item.displayDetailUrl !== undefined &&
          item.displayDetailUrl.startsWith("https://ipfs.io/")
        ) {
          return item.displayDetailUrl.replace(
            "https://ipfs.io/",
            "https://ipfs.3space.art/"
          );
        }
        return item.displayDetailUrl;
      }
    },
    onClickArtistApplyPage() {
      // Artist 버튼 클릭 시
      let routeUrl = this.$router.resolve({ path: "/apply" });
      window.open(routeUrl.href, "_blank");
    },
    onClickContactUsPage() {
      // Corporation 버튼 클릭 시
      let routeUrl = this.$router.resolve({ path: "/contact" });
      window.open(routeUrl.href, "_blank");
    },
  },
}
</script>
